<template>
    <div>
        <h4 class="workplatform-title">资源站点分布</h4>
        <Row>
            <i-col span="24">
                <span class="m-l-5 remark" v-for="item in stationCount" :key="item.stationId ">
                    {{item.stationName}}({{item.quantity }})
                </span>
            </i-col>
        </Row>
        <!-- 显示等级分布 -->
        <Row :gutter="8" class="p-b-5">
            <i-col v-for="(item,i) in levelCount" :key="i" span="4">
                <div :class="currentLevel ===item.level?'order-level-active':'order-level'" @click="handleSearchLevel(item)">
                    <span>{{item.levelName}}</span>
                    <span class="right">{{item.quantity}}</span>
                </div>
            </i-col>
        </Row>

        <Row class="table-title p-t-2">
            <i-col span="4">所属资产</i-col>
            <i-col span="4">所属站台</i-col>
            <i-col span="4">站台级别</i-col>
            <i-col span="4">方向</i-col>
            <i-col span="4">价格</i-col>
            <i-col span="4">操作</i-col>
        </Row>
        <Row v-for="(resource,index) in list" :key="index" class="p-t-2" :class="computedRowClassName(resource,index)">
            <i-col span="4">{{resource.assetName}}</i-col>
            <i-col span="4">{{resource.stationName}}</i-col>
            <i-col span="4">{{resource.stationLevelName}}</i-col>
            <i-col span="4">{{resource.directionName === ''? '-': resource.directionName}}</i-col>
            <i-col span="4">{{formatMoney(resource.useprice)}}</i-col>
            <i-col span="4">
                <a v-if="!customskuId" class="delete" @click="handleDeleteShow(resource)">删除</a>
            </i-col>
        </Row>

        <div class="paging_style">
            <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator @on-change="handlePageNumberChange"></Page>
        </div>
    </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'

import { getStationLevelList, getProductDetailPageByOrder, getResourceCountByOrderId } from '@/api/order/productdetail'
import { deleteResource } from '@/api/order/purchase'

export default {
  props: {
    params: {
      type: Object
    },
    customskuId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      list: [],
      query: { pageNumber: 1, pageSize: 10 },
      total: 0,
      levelCount: [],
      stationCount: [],
      currentLevel: undefined
    }
  },
  methods: {
    computedRowClassName (row, index) {
      if (row.conflict) {
        return 'table-conflict-row'
      } else if (index % 2 === 0) {
        return 'table-row-1'
      } else {
        return 'table-row-2'
      }
    },
    getStationCount () {
      const query = {
        orderId: this.params.orderId,
        productId: this.params.productId,
        customskuId: this.customskuId || undefined,
        schedules: JSON.stringify([{ startDate: this.params.startDate, endDate: this.params.endDate }]),
        priceType: this.params.priceType
      }
      getResourceCountByOrderId(query).then(res => {
        this.$emit('on-update-map', res)
        this.stationCount = res
      })
    },
    getLevelCount () {
      const query = this.params
      query.customskuId = this.customskuId || undefined
      const levelCount = new Map()
      getStationLevelList(query).then(res => {
        res.forEach(level => {
          level.stationLevelBeanList.forEach(sl => {
            const count = levelCount.get(sl.levelName) || { levleName: '', quantity: 0 }
            count.quantity += sl.quantity
            levelCount.set(sl.levelName, Object.assign({}, sl, count))
          })
        })
        this.levelCount = [...levelCount.values()]
      })
    },
    initPageData (level) {
      this.query.orderId = this.params.orderId
      this.query.productId = this.params.productId
      this.query.startDate = this.params.startDate
      this.query.endDate = this.params.endDate
      this.query.stationLevel = level || null
      getProductDetailPageByOrder(this.query).then(res => {
        this.total = res.totalRow
        this.list = res.list
      })
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.initPageData()
    },
    handleSearchLevel (param) {
      if (this.currentLevel === param.level) {
        this.currentLevel = undefined
      } else {
        this.currentLevel = param.level
      }
      this.initPageData(this.currentLevel)
    },
    handleDeleteShow (resource) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '点击确定后资源将会从您订单里删除，请谨慎操作',
        onOk: () => {
          this.handleDelete(resource)
        }

      })
    },
    handleDelete (resource) {
      const data = {
        orderId: this.params.orderId,
        resourceId: resource.resourceId,
        startDate: resource.startDate,
        endDate: resource.endDate
      }
      deleteResource(data).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '删除成功' })
          this.$emit('on-update-order', this.params.categoryId)
        }
      })
    },
    formatMoney (number) {
      return toMoney(number)
    }
  },
  mounted () {
    this.initPageData()
    this.getLevelCount()
    this.getStationCount()
  },
  watch: {
    params: function () {
      this.query.pageNumber = 1
      this.initPageData()
      this.getLevelCount()
      this.getStationCount()
    }
  }
}
</script>
